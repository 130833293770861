import React from 'react';
import { Route } from 'react-router-dom';
import { DeliveryFee } from '../DeliveryFee';
import { PickupFormula } from '../PickupFormula';

const customRoutes = [
  <Route exact path="/delivery-fee" component={DeliveryFee} />,
  <Route exact path="/pickup-formula" component={PickupFormula} />
];

export default customRoutes;
