import React, { useEffect, useState } from 'react';
import { reduce } from 'lodash/fp';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  SimpleForm,
  NumberInput,
  TextInput,
  DateField,
  Toolbar,
  SaveButton,
  showNotification,
  Button,
} from 'react-admin';
// eslint-disable-next-line no-unused-vars
import { css } from 'styled-components/macro';
import CancelIcon from '@material-ui/icons/Cancel';

import { fetchData, putData, PICKUP_POINTS } from '../../utils/configurableExpressionsApiService';
import { validations } from '../../utils/formValidations';

// custom styles using material-ui hook
const useStyles = makeStyles({
  updatedLabel: {
    margin: '10px 0',

    '& > div > span': {
      fontSize: '15px',
    },
  },
});

const CancelButton = () => (
  <Button
    color="primary"
    onClick={() => window.history.back()}
    style={{
      fontSize: '0.8125rem',
      marginLeft: '20px',
      padding: '4px 10px',
    }}
    label="Cancel"
  >
    <CancelIcon fontSize="small" />
  </Button>
);

// we use a custom form toolbar so we can avoid delete button which is otherwise added by default
const PickupFormulaToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect={false} />
    <CancelButton />
  </Toolbar>
);

const PickupFormulaFieldDetails = () => (
  <div
    css={`
      color: dimgray;
      margin-top: 25px;
      margin-bottom: 20px;

      h4 {
        margin: 0;
        font-weight: 600;
        font-size: 15px;
      }

      ul {
        margin-bottom: 0;

        li {
          margin: 8px 0;

          .highlight {
            color: #222;
            font-weight: 600;
          }
        }
      }
    `}
  >
    <h4>Rules for a valid expression</h4>
    <ul>
      <li>
        Available variables:  <span className="highlight">distance_relative</span>,{' '}
        <span className="highlight">distance</span>, <span className="highlight">biggest_distance</span>,{' '}
        <span className="highlight">inventory_relative</span>, <span className="highlight">inventory_weight</span>,{' '}
        <span className="highlight">distance_weight</span>.
      </li>
      <li>
        Available operations: <span className="highlight">+</span>,{' '}
        <span className="highlight">-</span>, <span className="highlight">*</span>,{' '}
        <span className="highlight">/</span>
      </li>
      <li>You can also use parentheses nesting.</li>
    </ul>
  </div>
);

const PickupFormula = connect(
  null,
  { showNotification },
)(props => {
  const { showNotification } = props;

  const [pickupFormulaData, setPickupFormulaData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const classes = useStyles();

  // useEffect hook to fetch current setup data as soon as possible
  useEffect(() => {
    fetchData(PICKUP_POINTS)
      .then(data => {
        setPickupFormulaData(data);
      })
      .catch(() => {
        showNotification(`Error: couldn't retrieve current pickup formula data.`, 'error');
      });
  }, [showNotification]);

  // callback used to handle data saving
  const saveData = record => {
    // we set saving to true just before making the request
    setIsSaving(true);
    // transformation needed to comply with request's body shape accepted by the API endpoint
    const data = reduce.convert({ cap: false })((a, v, k) => {
      a[k] = `${v}`;
      return a;
    }, {})(record);

    putData(PICKUP_POINTS, data)
      .then(record => {
        document.activeElement.blur();
        setPickupFormulaData(record);
        showNotification('Data saved successfully.');
      })
      .catch(() => {
        showNotification(`Error: couldn't save data, please verify and try again.`, 'warning');
      })
      .finally(() => {
        // we set saving to false again regardless of the request's success or failure
        setIsSaving(false);
      });
  };

  return (
    <>
      <h2>Pickup Points Formula Setup</h2>
      <SimpleForm
        record={pickupFormulaData}
        save={saveData}
        saving={isSaving}
        toolbar={<PickupFormulaToolbar />}
      >
        <NumberInput
          source="inventory_weight"
          label="Inventory Weight"
          validate={validations.inventoryWeight}
          className={classes.input}
        />
        <NumberInput
          source="distance_weight"
          label="Distance Weight"
          min={0}
          validate={validations.distanceWeight}
        />
        <TextInput multiline fullWidth source="expression" validate={validations.pickupExpression} />
        <PickupFormulaFieldDetails />
        <DateField
          source="updated_at"
          label="Last updated at"
          locales="en-US"
          showTime
          className={classes.updatedLabel}
          options={{
            dateStyle: 'medium',
            timeStyle: 'medium',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }}
        />
      </SimpleForm>
    </>
  );
});

export { PickupFormula };
