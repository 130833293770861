import React from 'react';
import { Admin, Resource, Login } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import StoreSupplierIcon from '@material-ui/icons/DirectionsBus';
import StoreIcon from '@material-ui/icons/Store';
import VehicleIcon from '@material-ui/icons/DriveEta';
import DriverIcon from '@material-ui/icons/Person';
import OrderIcon from '@material-ui/icons/Receipt';

import authProvider from '../../utils/authProvider';
import jsonApiClient from '../../utils/dataProvider';
import customRoutes from './customRoutes';

import sagas from '../../sagas';
import Layout from './Layout';
import Dashboard from '../Dashboard';
import { SupplierList, SupplierShow, SupplierEdit, SupplierCreate } from '../Supplier';
import { StoreList, StoreShow, StoreEdit, StoreCreate } from '../Store';
import { DriverList, DriverEdit, DriverShow, DriverCreate } from '../Driver';
import { VehicleList, VehicleEdit, VehicleShow, VehicleCreate } from '../Vehicle';
import { OrderList, OrderEdit, OrderShow } from '../Order';
import { UserList, UserEdit, UserShow, UserCreate } from '../User';

const dataProvider = jsonApiClient;

const CustomLogin = () => <Login backgroundImage="/admin-background.jpg" />;

const enMessages = {
  ...englishMessages,
  drivers: {
    notifications: {
      checkr_invitation: {
        success: 'Driver invited to Checkr.',
        error: 'An error occurred while inviting driver to Checkr.',
      },
    },
  },
};

const Index = () => (
  <Admin
    title="RUNR - Admin"
    layout={Layout}
    loginPage={CustomLogin}
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
    customRoutes={customRoutes}
    customSagas={sagas}
    locale="en"
    i18nProvider={polyglotI18nProvider(() => enMessages, 'en', {
      allowMissing: true,
    })}
  >
    <Resource
      name="suppliers"
      list={SupplierList}
      show={SupplierShow}
      edit={SupplierEdit}
      create={SupplierCreate}
      icon={StoreSupplierIcon}
      options={{ label: 'Suppliers' }}
    />
    <Resource
      name="supplier_stores"
      list={StoreList}
      show={StoreShow}
      edit={StoreEdit}
      create={StoreCreate}
      icon={StoreIcon}
      options={{ label: 'Stores' }}
    />
    <Resource
      name="users"
      list={UserList}
      show={UserShow}
      edit={UserEdit}
      create={UserCreate}
      icon={DriverIcon}
      options={{ label: 'Users' }}
    />
    <Resource
      name="drivers"
      list={DriverList}
      show={DriverShow}
      edit={DriverEdit}
      create={DriverCreate}
      icon={DriverIcon}
      options={{ label: 'Drivers' }}
    />
    <Resource
      name="vehicles"
      list={VehicleList}
      show={VehicleShow}
      edit={VehicleEdit}
      create={VehicleCreate}
      icon={VehicleIcon}
      options={{ label: 'Vehicles' }}
    />
    <Resource
      name="orders"
      list={OrderList}
      show={OrderShow}
      edit={OrderEdit}
      icon={OrderIcon}
      options={{ label: 'Orders' }}
    />
    <Resource name="order_photos" options={{ label: 'Order Photos' }} />
  </Admin>
);

export default Index;
