import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

import { BaseActions, SaveOnCreateToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const StoreCreate = props => (
  <Create title="RUNR - Create new store" actions={<BaseActions />} {...props}>
    <SimpleForm toolbar={<SaveOnCreateToolbar />} redirect={props.basePath}>
      <TextInput source="name" validate={validations.name} />
      <TextInput source="store_number" validate={validations.name} />
      <ReferenceInput source="supplier_id" reference="suppliers" resource={props.resource}>
        <SelectInput optionText="name" validate={validations.required} />
      </ReferenceInput>
      <TextInput source="street_number" validate={validations.name} />
      <TextInput source="street" validate={validations.name} />
      <TextInput source="city" validate={validations.name} />
      <TextInput source="state" validate={validations.name} />
      <TextInput source="country" validate={validations.name} />
      <TextInput source="postal_code" validate={validations.zipCode} />
      <TextInput source="phone" validate={validations.name} />
      <TextInput source="email" validate={validations.email} />
      <TextInput source="driver_instructions" multiline fullWidth validate={validations.text} />
    </SimpleForm>
  </Create>
);

export { StoreCreate };
