import React from 'react';
import { Edit, SimpleForm, TextInput, TextField } from 'react-admin';

import { EditActions, SaveOnEditToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const StoreEditTitle = ({ record }) => (
  <span>RUNR - Edit store {record ? `"${record.name}"` : ''}</span>
);

const StoreEdit = props => (
  <Edit title={<StoreEditTitle />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<SaveOnEditToolbar />} redirect="show">
      <TextField source="slug" label="Store ID" />
      <TextInput source="name" validate={validations.name} />
      <TextInput source="store_number" validate={validations.name} />
      <TextInput source="street_number" validate={validations.name} />
      <TextInput source="street" validate={validations.name} />
      <TextInput source="city" validate={validations.name} />
      <TextInput source="state" validate={validations.name} />
      <TextInput source="country" validate={validations.name} />
      <TextInput source="postal_code" validate={validations.zipCode} />
      <TextInput source="phone" validate={validations.name} />
      <TextInput source="email" validate={validations.email} />
      <TextInput source="driver_instructions" multiline fullWidth validate={validations.text} />
    </SimpleForm>
  </Edit>
);

export { StoreEdit };
