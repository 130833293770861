import React from 'react';
import { List, Datagrid, TextField, EditButton, Filter, TextInput, SelectInput } from 'react-admin';

import { ListActionButtons, ListPagination } from '../common';

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by email" source="email" alwaysOn />
    <SelectInput
      source="level"
      choices={[
        { id: 'admin', name: 'Admin' },
        { id: 'moderator', name: 'Moderator' },
        { id: 'guest', name: 'Guest' },
      ]}
    />
  </Filter>
);

const UserList = props => (
  <List
    title="RUNR - Users"
    filters={<ListFilters />}
    bulkActionButtons={<ListActionButtons />}
    pagination={<ListPagination />}
    perPage={25}
    sort={{ field: 'email', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="email" />
      <TextField source="level" />
      <EditButton />
    </Datagrid>
  </List>
);

export { UserList };
