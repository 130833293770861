import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

import { BaseActions, SaveOnCreateToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const SupplierCreate = props => (
  <Create title="RUNR - Create new supplier" actions={<BaseActions />} {...props}>
    <SimpleForm toolbar={<SaveOnCreateToolbar />} redirect={props.basePath}>
      <TextInput source="name" validate={validations.name} />
      <TextInput source="street_number" validate={validations.tinyName} />
      <TextInput source="street" validate={validations.name} />
      <TextInput source="city" validate={validations.name} />
      <TextInput source="state" validate={validations.name} />
      <TextInput source="country" validate={validations.name} />
      <TextInput source="postal_code" validate={validations.zipCode} />
      <TextInput source="phone" validate={validations.name} />
      <TextInput source="email" validate={validations.email} />
      <TextInput source="website" validate={validations.name} />
      <TextInput source="observation" validate={validations.text} multiline fullWidth />
    </SimpleForm>
  </Create>
);

export { SupplierCreate };
