import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, BooleanInput } from 'react-admin';

import { BaseActions, SaveOnCreateToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const DriverCreate = props => (
  <Create title="RUNR - Create new Driver" actions={<BaseActions />} {...props}>
    <SimpleForm toolbar={<SaveOnCreateToolbar />} redirect={props.basePath}>
      <TextInput source="first_name" validate={validations.name} />
      <TextInput source="last_name" validate={validations.name} />
      <TextInput source="email" validate={validations.email} />
      <TextInput source="phone" validate={validations.name} />
      <BooleanInput source="driving_now" defaultValue={false} />
      <SelectInput
        source="driving_status"
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'approved', name: 'Approved' },
          { id: 'denied', name: 'Denied' },
        ]}
      />
    </SimpleForm>
  </Create>
);

export { DriverCreate };
