import React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  SelectInput,
  TextInput,
  ShowController,
  Show,
} from 'react-admin';

import { EditActions, SaveOnEditToolbar } from '../common';

import ChangeOrderSupplier from './ChangeOrderSupplier/ChangeOrderSupplier';

const OrderEditTitle = ({ record }) => (
  <span>RUNR - Edit Order {record ? `"${record.name}"` : ''}</span>
);

const OrderEdit = props => {
  const orderUrl = props.match.url;
  return (
    <Edit title={<OrderEditTitle />} actions={<EditActions />} {...props}>
      <SimpleForm toolbar={<SaveOnEditToolbar />} redirect="show">
        <SelectInput
          source="status"
          choices={[
            { id: 'received', name: 'Received' },
            { id: 'prepared', name: 'Prepared' },
            { id: 'accepted', name: 'Accepted' },
            { id: 'started', name: 'Started' },
            { id: 'picked', name: 'Picked-up' },
            { id: 'delivered', name: 'Delivered' },
            { id: 'cancelled', name: 'Cancelled' },
          ]}
        />
        <ShowController {...props}>
          {controllerProps => (
            <Show {...props} {...controllerProps} actions={null}>
              {controllerProps.record.status === 'received' ? (
                <ChangeOrderSupplier orderUrl={orderUrl} />
              ) : null}
            </Show>
          )}
        </ShowController>
        <TextField source="shopify_order_id" />
        <TextInput source="purchase_order_number" label="PO Number" />
        <TextInput source="notes" label="Notes" multiline />
      </SimpleForm>
    </Edit>
  );
};

export { OrderEdit };
