import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import { EditActions, SaveOnEditToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const UserEditTitle = ({ record }) => (
  <span>RUNR - Edit User {record ? `"${record.name}"` : ''}</span>
);

const UserEdit = props => (
  <Edit title={<UserEditTitle />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<SaveOnEditToolbar />} redirect="show">
      <TextInput source="email" validate={validations.email} />
      <TextInput source="password" type="password" validate={validations.name} />
      <TextInput source="level" type="text" defaultValue="admin" disabled />
    </SimpleForm>
  </Edit>
);

export { UserEdit };
