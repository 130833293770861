import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, ReferenceInput, NumberInput } from 'react-admin';

import { EditActions, SaveOnEditToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const VehicleEditTitle = ({ record }) => (
  <span>RUNR - Edit Vehicle {record ? `"${record.name}"` : ''}</span>
);

const VehicleEdit = props => (
  <Edit title={<VehicleEditTitle />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<SaveOnEditToolbar />} redirect="show">
      <ReferenceInput label="Driver" source="user_id" reference="drivers" allowEmpty={false}>
        <SelectInput optionText="first_name" />
      </ReferenceInput>
      <TextInput source="brand" validate={validations.name} label="Make" />
      <TextInput source="model" validate={validations.name} />
      <NumberInput source="year" />
      <TextInput source="color" validate={validations.name} />
      <TextInput source="license" validate={validations.name} />
      <TextInput source="vin" validate={validations.vin} />
    </SimpleForm>
  </Edit>
);

export { VehicleEdit };
