export default {
  login: ({ username, password }) => {
    const request = new Request(`${process.env.REACT_APP_BASE_URL}/auth`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token, refresh_token }) => {
        localStorage.setItem('access_token', access_token.token);
        localStorage.setItem('refresh_token', refresh_token.token);
      });
  },

  logout: () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return Promise.resolve();
  },

  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      return Promise.reject({ redirectTo: '/login' });
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    return localStorage.getItem('access_token')
      ? Promise.resolve()
      : Promise.reject({ redirectTo: '/login' });
  },

  getPermissions: () => Promise.resolve(),
};
