import React from 'react';
import { useStyles } from '../common/styles';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

import { ShowActions } from '../common';

const UserTitle = ({ record }) => <span>RUNR - View User {record ? `"${record.email}"` : ''}</span>;

const UserShow = props => {
  const classes = useStyles();

  return (
    <Show title={<UserTitle />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="email" className={classes.field} />
        <TextField source="level" className={classes.field} />
      </SimpleShowLayout>
    </Show>
  );
};

export { UserShow };
