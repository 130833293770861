import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

import { BaseActions, SaveOnCreateToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const UserCreate = props => (
  <Create title="RUNR - Create new User" actions={<BaseActions />} {...props}>
    <SimpleForm toolbar={<SaveOnCreateToolbar />} redirect={props.basePath}>
      <TextInput source="email" validate={validations.email} />
      <TextInput source="password" type="password" validate={validations.name} />
      <TextInput source="level" type="text" defaultValue="admin" disabled />
    </SimpleForm>
  </Create>
);

export { UserCreate };
