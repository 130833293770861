import React from 'react';
import { useStyles } from '../common/styles';
import { Show, SimpleShowLayout, TextField, EmailField, DateField, UrlField } from 'react-admin';

import { ShowActions } from '../common';

const SupplierTitle = ({ record }) => (
  <span>RUNR - View supplier {record ? `"${record.name}"` : ''}</span>
);

const SupplierShow = props => {
  const classes = useStyles();

  return (
    <Show title={<SupplierTitle />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" className={classes.field} />
        <TextField source="street_number" className={classes.field} />
        <TextField source="street" className={classes.field} />
        <TextField source="city" className={classes.field} />
        <TextField source="state" className={classes.field} />
        <TextField source="country" className={classes.field} />
        <TextField source="postal_code" className={classes.field} />
        <TextField source="phone" className={classes.field} />
        <EmailField source="email" className={classes.field} />
        <UrlField source="website" className={classes.field} />
        <TextField source="observation" className={classes.field} />
        <DateField source="inserted_at" className={classes.field} />
        <DateField source="updated_at" className={classes.field} />
      </SimpleShowLayout>
    </Show>
  );
};

export { SupplierShow };
