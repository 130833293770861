import {
  concat,
  filter,
  includes,
  map,
  omit,
  orderBy,
  pipe,
  reduce,
  slice,
  snakeCase,
  toLower,
} from 'lodash/fp';

import { HttpError } from 'react-admin';
/**
 * Process data received from the API to apply search, filters, sorting and pagination client-side.
 *
 * @param {array} data
 * @param {object} params Contains filter, sort and pagination info
 * @returns {array} the data after applying params processing
 */
function processDataByParams(data, params) {
  const { sort, filter: filters, pagination } = params;

  const { field, order } = sort || {};
  const { q: searchQuery } = filters || {};
  const { page = 1, perPage = 0 } = pagination || {};

  const sliceStart = (page - 1) * perPage;
  const sliceEnd = perPage ? sliceStart + perPage : data.length;

  return pipe(
    map(f => filter(f))(
      reduce.convert({ cap: false })((a, v, k) => concat(a, { [k]: v }), [])(omit('q', filters)),
    ),
    filter(o => includes(toLower(searchQuery), toLower(o.name))),
    orderBy(field, toLower(order)),
    slice(sliceStart, sliceEnd),
  )(data);
}

const API_URL = process.env.REACT_APP_BASE_URL;

function getErrorMessage(json) {
  if (typeof json === 'string') {
    return json;
  }

  if (json && json.errors) {
    const message = json.errors.map(({ field, reason }) => `${field}: ${reason}`).join('</br>');
    return message;
  }

  return 'unknow error';
}

const fetchJson = (url, options) => {
  const token = localStorage.getItem('access_token');
  options = options || {};

  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json',
    });
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json; charset=utf-8');
  }

  requestHeaders.set('Authorization', `Bearer ${token}`);

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        const message = getErrorMessage(json);
        return Promise.reject(new HttpError(message, status, json));
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export default {
  getList: (resource, params) => {
    const url = `${API_URL}/${resource}`;

    return fetchJson(url).then(({ json }) => {
      return {
        data: processDataByParams(json, params),
        total: json.length,
      };
    });
  },

  getMany: (resource, params) => {
    const url = `${API_URL}/${resource}`;

    return fetchJson(url).then(({ json }) => {
      return {
        data: processDataByParams(json, params),
        total: json.length,
      };
    });
  },

  getManyReference: (resource, params, options) => {
    let url;
    if (resource === 'order_photos') {
      url = `${API_URL}/${resource}/order/${params.id}`;
    } else {
      url = `${API_URL}/${resource}/`;
    }

    return fetchJson(url).then(({ json }) => {
      return {
        data: processDataByParams(json, params),
        total: json.length,
      };
    });
  },

  getOne: (resource, params) => {
    let url = `${API_URL}/${resource}/${params.id}`;

    if (resource === 'drivers') {
      // driver GET ONE should be done on user endpoint ¯\_(ツ)_/¯
      url = `${API_URL}/users/${params.id}`;
    }

    return fetchJson(url).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    const url = `${API_URL}/${resource}`;

    const body = params.data;

    if (resource === 'suppliers') {
      body.slug = snakeCase(body.name);
    }

    const reqParams = { method: 'POST', body: JSON.stringify(body) };

    return fetchJson(url, reqParams).then(({ json }) => ({ data: json }));
  },

  update: (resource, params) => {
    const url = `${API_URL}/${resource}/${params.id}`;
    const reqParams = { method: 'PUT', body: JSON.stringify(params.data) };

    return fetchJson(url, reqParams).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) => {
    let url = `${API_URL}/${resource}/${params.id}`;

    if (resource === 'drivers') {
      // driver DELETE should be done on user endpoint ¯\_(ツ)_/¯
      url = `${API_URL}/users/${params.id}`;
    }

    const reqParams = { method: 'DELETE' };

    return fetchJson(url, reqParams).then(() => ({ id: params.id }));
  },

  deleteMany: (resource, params) => {
    if (resource === 'drivers') {
      // driver DELETE should be done on user endpoint ¯\_(ツ)_/¯
      resource = 'users';
    }

    return Promise.all(
      params.ids.map(id =>
        fetchJson(`${API_URL}/${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    ).then(responses => ({ data: responses.map(({ json }) => json) }));
  },

  checkrInvite: driver => {
    const reqParams = { method: 'POST' };
    const url = `${API_URL}/drivers/${driver.id}/checkr_invite`;

    return fetchJson(url, reqParams).then(() => ({ data: {} }));
  },
};
