import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

export default () => (
  <Card>
    <Title title="RUNR - Dashboard" />
    <CardContent>
      <p>Hi!</p>
      <p>
        This is the admin dashboard. Here we can place stats and graphs to give important
        information at a glance.
      </p>
      <p>By the moment you can navigate Suppliers, Stores and Delivery Fee pages.</p>
      <p>
        This admin has an "undo" feature that allows the user to cancel an action within the next 5
        seconds after it took place in some cases. You will see a little dialog showing up from the
        bottom offering this option.
      </p>
    </CardContent>
  </Card>
);
