import React, { useState, useEffect } from 'react';
import { map, find } from 'lodash/fp';
import './change-order-supplier.css';
import DropDown from '../../common/Dropdown/Dropdown';
import { getOrderData } from '../../../utils/configurableExpressionsApiService/index';
import { changeSupplier } from '../../../utils/configurableExpressionsApiService/index';

const ChangeOrderSupplier = props => {
  const [orderDetails, setOrderDetails] = useState({});
  const [supplierStores, setSupplierStores] = useState({});
  const [newSlug, setNewSlug] = useState('');
  const [orderId, setOrderId] = useState('');
  const [succesMessage, setSuccesMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const orderUrl = props.orderUrl;
  useEffect(() => {
    setSuccesMessage(null);
    setErrorMessage(null);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const resSupplierStores = await getOrderData('/supplier_stores');
    setSupplierStores(resSupplierStores);
    const resOrderData = await getOrderData(orderUrl);
    setOrderId(resOrderData.json.id);
    const items = Array.from(resOrderData.json.items, item => {
      const obj = {
        id: item.id,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        supplier_store_id: item.supplier_store_id,
      };
      return obj;
    });

    const itemsSlugNumberArr = map(item => {
      const objToAdd = find(store => {
        let obj = {};
        if (item.supplier_store_id === store.id) {
          return obj;
        }
      }, resSupplierStores.json);
      let newObj = { ...item };
      newObj.slug = objToAdd.slug;
      newObj.store_number = objToAdd.store_number;
      newObj.store_name = objToAdd.name;
      return newObj;
    }, items);
    setOrderDetails(itemsSlugNumberArr);
  };

  const getNewSlug = slug => {
    setNewSlug(slug);
  };

  const submitChangedStore = async (oldSlug, newSlug) => {
    const payload = {
      previous_store_slug: oldSlug,
      new_store_slug: newSlug,
    };
    let message = await changeSupplier(orderId, payload);
    if (message === 'success') {
      setSuccesMessage('Supplier changed successfully');
    } else {
      setErrorMessage('An error ocurred');
    }
  };

  const renderTableData = () => {
    return (
      <>
        <div className="message-box">
          {succesMessage ? <div className="success-message">{succesMessage}</div> : null}
          {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
        </div>
        <table>
          <thead>
            <tr>
              <th className="table-items-header">Name</th>
              <th className="table-items-header">Price</th>
              <th className="table-items-header">Quantity</th>
              <th className="table-items-header">Store Name</th>
              <th className="table-items-header">Store Number</th>
              <th className="table-items-header">New Store</th>
            </tr>
          </thead>
          <tbody>
            {map(item => {
              return (
                <tr key={item.id}>
                  <td className="table-items">{item.name}</td>
                  <td className="table-items">{item.price}</td>
                  <td className="table-items">{item.quantity}</td>
                  <td className="table-items">{item.store_name}</td>
                  <td className="table-items">{item.store_number}</td>
                  <td>
                    <DropDown list={supplierStores} getNewSlug={getNewSlug} />
                  </td>
                  <td className="table-items">
                    <div
                      className="submit-change-store-button"
                      onClick={() => {
                        if (newSlug) {
                          submitChangedStore(item.slug, newSlug);
                        }
                      }}
                    >
                      Submit
                    </div>
                  </td>
                </tr>
              );
            }, orderDetails)}
          </tbody>
        </table>
      </>
    );
  };
  return (
    <div className="total-wrapper">
      <h5 className="title">Order Details - Change Supplier</h5>
      <br />
      <div className="table-body-wrapper">{renderTableData()}</div>
    </div>
  );
};

export default ChangeOrderSupplier;
