import React, { useEffect, useState } from 'react';
import { reduce } from 'lodash/fp';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  SimpleForm,
  NumberInput,
  TextInput,
  DateField,
  Toolbar,
  SaveButton,
  showNotification,
  Button,
} from 'react-admin';
// eslint-disable-next-line no-unused-vars
import { css } from 'styled-components/macro';
import CancelIcon from '@material-ui/icons/Cancel';

import { fetchData, putData, DELIVERY_FEE } from '../../utils/configurableExpressionsApiService';
import { validations } from '../../utils/formValidations';

// custom styles using material-ui hook
const useStyles = makeStyles({
  updatedLabel: {
    margin: '10px 0',

    '& > div > span': {
      fontSize: '15px',
    },
  },
});

const CancelButton = () => (
  <Button
    color="primary"
    onClick={() => window.history.back()}
    style={{
      fontSize: '0.8125rem',
      marginLeft: '20px',
      padding: '4px 10px',
    }}
    label="Cancel"
  >
    <CancelIcon fontSize="small" />
  </Button>
);

// we use a custom form toolbar so we can avoid delete button which is otherwise added by default
const DeliveryFeeToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect={false} />
    <CancelButton />
  </Toolbar>
);

const DriverFeeFieldDetails = () => (
  <div
    css={`
      color: dimgray;
      margin-top: 25px;
      margin-bottom: 20px;

      h4 {
        margin: 0;
        font-weight: 600;
        font-size: 15px;
      }

      ul {
        margin-bottom: 0;

        li {
          margin: 8px 0;

          .highlight {
            color: #222;
            font-weight: 600;
          }
        }
      }
    `}
  >
    <h4>Rules for a valid expression</h4>
    <ul>
      <li>
        Available variables: <span className="highlight">time</span>,{' '}
        <span className="highlight">weight</span>, <span className="highlight">distance</span>,{' '}
        <span className="highlight">minute_cost</span>,<span className="highlight">lbs_cost</span>,{' '}
        <span className="highlight">mile_cost</span>.
      </li>
      <li>
        Available operations: <span className="highlight">+</span>,{' '}
        <span className="highlight">-</span>, <span className="highlight">*</span>,{' '}
        <span className="highlight">/</span>
      </li>
      <li>You can also use parentheses nesting.</li>
    </ul>
  </div>
);

const DeliveryFee = connect(
  null,
  { showNotification },
)(props => {
  const { showNotification } = props;

  const [deliveryFeeData, setDeliveryFeeData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const classes = useStyles();

  // useEffect hook to fetch current setup data as soon as possible
  useEffect(() => {
    fetchData(DELIVERY_FEE)
      .then(data => {
        setDeliveryFeeData(data);
      })
      .catch(() => {
        showNotification(`Error: couldn't retrieve current delivery fee data.`, 'error');
      });
  }, [showNotification]);

  // callback used to handle data saving
  const saveData = record => {
    // we set saving to true just before making the request
    setIsSaving(true);
    // transformation needed to comply with request's body shape accepted by the API endpoint
    const data = reduce.convert({ cap: false })((a, v, k) => {
      a[k] = `${v}`;
      return a;
    }, {})(record);

    putData(DELIVERY_FEE, data)
      .then(record => {
        document.activeElement.blur();
        setDeliveryFeeData(record);
        showNotification('Data saved successfully.');
      })
      .catch(() => {
        showNotification(`Error: couldn't save data, please verify and try again.`, 'warning');
      })
      .finally(() => {
        // we set saving to false again regardless of the request's success or failure
        setIsSaving(false);
      });
  };

  // TODO: add step to number inputs, which is not working right now
  // TODO: check if possible to add min value to number inputs, which is not present in docs

  return (
    <>
      <h2>Delivery Fee Setup</h2>
      <SimpleForm
        record={deliveryFeeData}
        save={saveData}
        saving={isSaving}
        toolbar={<DeliveryFeeToolbar />}
      >
        <NumberInput
          source="driver_fee"
          label="Driver Fee (between 0 and 1)"
          validate={validations.driverFee}
          className={classes.input}
        />
        <NumberInput
          source="lbs_cost"
          label="Cost per Lb ($)"
          min={0}
          validate={validations.baseCostFee}
        />
        <NumberInput
          source="mile_cost"
          label="Cost per Mile ($)"
          validate={validations.baseCostFee}
        />
        <NumberInput
          source="minute_cost"
          label="Cost per Minute ($)"
          validate={validations.baseCostFee}
        />
        <TextInput multiline fullWidth source="expression" validate={validations.feeExpression} />
        <DriverFeeFieldDetails />
        <DateField
          source="updated_at"
          label="Last updated at"
          locales="en-US"
          showTime
          className={classes.updatedLabel}
          options={{
            dateStyle: 'medium',
            timeStyle: 'medium',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }}
        />
      </SimpleForm>
    </>
  );
});

export { DeliveryFee };
