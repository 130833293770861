import React from 'react';
import { useStyles } from '../common/styles';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';

import { ShowActions } from '../common';

const VehicleTitle = ({ record }) => (
  <span>
    RUNR - View Vehicle {record ? `"${record.year} ${record.brand} ${record.model}"` : ''}
  </span>
);

const VehicleShow = props => {
  const classes = useStyles();

  return (
    <Show title={<VehicleTitle />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout>
        <ReferenceField
          source="user_id"
          reference="drivers"
          basePath={props.basePath}
          className={classes.field}
        >
          <TextField source="first_name" />
        </ReferenceField>
        <TextField source="brand" className={classes.field} label="Make" />
        <TextField source="model" className={classes.field} />
        <TextField source="year" className={classes.field} />
        <TextField source="color" className={classes.field} />
        <TextField source="license" className={classes.field} />
        <TextField source="vin" className={classes.field} />
      </SimpleShowLayout>
    </Show>
  );
};

export { VehicleShow };
