import { makeStyles } from '@material-ui/styles';

// custom styles using material-ui hook
const useStyles = makeStyles(theme => ({
  field: {
    margin: '10px 0',

    '& > div > div': {
      minHeight: '21px',
      paddingTop: '2px',

      '& > span': {
        fontSize: '16px',
      },
    },
  },
  progressWrapper: {
    paddingTop: 5,
  },
}));

export { useStyles };
