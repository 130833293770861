import React from 'react';
import { TopToolbar, Button } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';

const CancelButton = () => (
  <Button
    color="primary"
    onClick={() => window.history.back()}
    style={{
      fontSize: '0.8125rem',
      marginLeft: '20px',
      padding: '4px 10px',
    }}
    label="Cancel"
  >
    <CancelIcon fontSize="small" />
  </Button>
);

const BaseActions = ({ children }) => (
  <TopToolbar>
    {children}
    <CancelButton />
  </TopToolbar>
);

export { BaseActions };
