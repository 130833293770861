import React from 'react';
import { useStyles } from '../common/styles';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  SelectField,
  FunctionField,
} from 'react-admin';
import InviteButton from './InviteButton';

import { DocumentField } from './DocumentField';
import { ShowActions } from '../common';

const DriverTitle = ({ record }) => (
  <span>RUNR - View Driver {record ? `"${record.first_name} ${record.last_name}"` : ''}</span>
);
const DriverShow = props => {
  const { data } = props;
  const classes = useStyles();

  return (
    <Show
      title={<DriverTitle />}
      actions={
        <ShowActions>
          <InviteButton record={data} />
        </ShowActions>
      }
      {...props}
    >
      <SimpleShowLayout>
        <FunctionField label="Name" render={record => `${record.first_name} ${record.last_name}`} />
        <TextField source="email" className={classes.field} />
        <TextField source="phone" className={classes.field} />
        <BooleanField source="driving_now" defaultValue={false} />
        <SelectField
          source="driving_status"
          choices={[
            { id: 'pending', name: 'Pending' },
            { id: 'approved', name: 'Approved' },
            { id: 'denied', name: 'Denied' },
          ]}
        />
        <DocumentField
          source="driver_license_photo_url"
          label="Driver license photo"
          className={classes.field}
        />
        <DocumentField
          source="vehicle_registration_photo_url"
          label="Vehicle registration photo url"
          className={classes.field}
        />
        <DocumentField
          source="driver_photo_url"
          label="Driver photo url"
          className={classes.field}
        />
        <DocumentField
          source="insurance_photo_url"
          label="Insurance photo url"
          className={classes.field}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export { DriverShow };
