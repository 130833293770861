import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
} from 'react-admin';

import { BaseActions, SaveOnCreateToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const VehicleCreate = props => (
  <Create title="RUNR - Create new Vehicle" actions={<BaseActions />} {...props}>
    <SimpleForm toolbar={<SaveOnCreateToolbar />} redirect={props.basePath}>
      <ReferenceInput label="Driver" source="user_id" reference="drivers" allowEmpty={false}>
        <SelectInput optionText="first_name" />
      </ReferenceInput>
      <TextInput source="brand" validate={validations.name} label="Make" />
      <TextInput source="model" validate={validations.name} />
      <NumberInput source="year" />
      <TextInput source="color" validate={validations.name} />
      <TextInput source="license" validate={validations.name} />
      <TextInput source="vin" validate={validations.vin} />
    </SimpleForm>
  </Create>
);

export { VehicleCreate };
