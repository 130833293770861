import { DELETE_MANY } from './constants';

const crudDeleteMany = (ids, basePath) => ({
  type: DELETE_MANY,
  payload: { ids },
  meta: {
    onSuccess: {
      notification: { body: 'Elements deleted.', level: 'info' },
      redirectTo: basePath,
      refresh: true,
    },
    onError: {
      notification: { body: `Error: could not delete all elements`, level: 'warning' },
      refresh: true,
    },
  },
});

export { crudDeleteMany };
