import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const SaveOnEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save and edit" redirect={false} submitOnEnter />
    <SaveButton label="Save and show" redirect="show" submitOnEnter={false} variant="outlined" />
  </Toolbar>
);

export { SaveOnEditToolbar };
