import React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  FunctionField,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';

import { ListActionButtons, ListPagination } from '../common';

const DriverList = props => {
  const ListFilters = props => (
    <Filter {...props}>
      <TextInput
        label="Search by name"
        source="first_name"
        alwaysOn
        resettable
        helperText="Must be a complete name (case sensitive)"
      />
      <TextInput
        label="Search by last name"
        source="last_name"
        alwaysOn
        resettable
        helperText="Must be a complete last name (case sensitive)"
      />
    </Filter>
  );

  return (
    <List
      title="RUNR - Drivers"
      filters={<ListFilters resource={props.resource} />}
      bulkActionButtons={<ListActionButtons />}
      pagination={<ListPagination />}
      perPage={25}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <FunctionField label="Name" render={record => `${record.first_name} ${record.last_name}`} />
        <TextField source="email" />
        <TextField source="phone" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export { DriverList };
