import React from 'react';
import { List, Datagrid, TextField, EmailField, UrlField, EditButton } from 'react-admin';

import { ListFilters, ListActionButtons, ListPagination } from '../common';

const SupplierList = props => (
  <List
    title="RUNR - Suppliers"
    filters={<ListFilters resource={props.resource} />}
    bulkActionButtons={<ListActionButtons />}
    pagination={<ListPagination />}
    perPage={25}
    sort={{ field: 'name', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="street_number" />
      <TextField source="street" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="postal_code" />
      <TextField source="phone" />
      <EmailField source="email" />
      <UrlField source="website" />
      <EditButton />
    </Datagrid>
  </List>
);

export { SupplierList };
