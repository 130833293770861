/* eslint-disable no-unused-expressions */
import React from 'react';
import './dropdown-menu.css';
import { sortBy } from 'lodash/fp';

const Dropdown = ({ list, getNewSlug }) => {
  const supplierStoreList = sortBy(
    [
      'name',
      function(o) {
        return Math.floor(o.store_number / 1);
      },
    ],
    list.json,
  );

  const selectionClick = event => {
    let selected = event.target.value;
    getNewSlug(selected);
  };

  return (
    <label className="dropdown">
      <select onChange={selectionClick} defaultValue="Select New Store" className="button">
        <option value="Select New Store" disabled defaultValue="Select New Store">
          Select New Store
        </option>
        {supplierStoreList.map(item => (
          <option key={item.slug} value={item.slug}>
            {item.name} - {item.store_number}
          </option>
        ))}
      </select>
    </label>
  );
};

export default Dropdown;
