import React from 'react';
import { TopToolbar, EditButton, Button } from 'react-admin';

const ShowActions = ({ basePath, data, children }) => (
  <TopToolbar>
    {React.Children.map(children, child => React.cloneElement(child, { record: data }))}
    <EditButton basePath={basePath} record={data} />
    <Button color="primary" onClick={() => window.history.back()} label="Go Back" />
  </TopToolbar>
);

export { ShowActions };
