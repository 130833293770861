import React, { createElement } from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import Dashboard from '@material-ui/icons/Dashboard';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';

// import Responsive from '../layout/Responsive';

const Menu = ({ resources, onMenuClick, logout }) => (
  <div>
    <MenuItemLink to="/" primaryText="Dashboard" leftIcon={<Dashboard />} onClick={onMenuClick} />
    {resources
      .filter(r => r.hasList)
      .map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={(resource.options && resource.options.label) || resource.name}
          leftIcon={createElement(resource.icon)}
          onClick={onMenuClick}
        />
      ))}
    <MenuItemLink
      to="/delivery-fee"
      primaryText="Delivery Fee"
      leftIcon={<MonetizationOn />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/pickup-formula"
      primaryText="Pickup Points System"
      leftIcon={<FormatListNumberedOutlinedIcon />}
      onClick={onMenuClick}
    />
  </div>
);

/*
<Responsive
  small={logout}
  medium={null} // Pass null to render nothing on larger devices
/>
*/

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
