import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, SelectInput } from 'react-admin';

import { EditActions, SaveOnEditToolbar } from '../common';
import { validations } from '../../utils/formValidations';

const DriverEditTitle = ({ record }) => (
  <span>RUNR - Edit Driver {record ? `"${record.first_name} ${record.last_name}"` : ''}</span>
);

const DriverEdit = props => (
  <Edit title={<DriverEditTitle />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<SaveOnEditToolbar />} redirect="show">
      <TextInput source="first_name" validate={validations.name} />
      <TextInput source="last_name" validate={validations.name} />
      <TextInput source="email" validate={validations.email} />
      <TextInput source="phone" validate={validations.name} />
      <BooleanInput source="driving_now" defaultValue={false} />
      <SelectInput
        source="driving_status"
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'denied', name: 'Denied' },
          { id: 'approved', name: 'Approved' },
          { id: 'completed', name: 'Completed' },
        ]}
      />
      <TextInput source="driver_license_photo_url" />
      <TextInput source="vehicle_registration_photo_url" />
      <TextInput source="driver_photo_url" />
      <TextInput source="insurance_photo_url" />
    </SimpleForm>
  </Edit>
);

export { DriverEdit };
