import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import { EditActions } from '../common';
import { validations } from '../../utils/formValidations';

const SupplierEditTitle = ({ record }) => (
  <span>RUNR - Edit supplier {record ? `"${record.name}"` : ''}</span>
);

const SupplierEdit = props => (
  <Edit title={<SupplierEditTitle />} actions={<EditActions />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={validations.name} />
      <TextInput source="street_number" validate={validations.tinyName} />
      <TextInput source="street" validate={validations.name} />
      <TextInput source="city" validate={validations.name} />
      <TextInput source="state" validate={validations.name} />
      <TextInput source="country" validate={validations.name} />
      <TextInput source="postal_code" validate={validations.zipCode} />
      <TextInput source="phone" validate={validations.name} />
      <TextInput source="email" validate={validations.email} />
      <TextInput source="website" validate={validations.name} />
      <TextInput source="observation" validate={validations.text} multiline fullWidth />
    </SimpleForm>
  </Edit>
);

export { SupplierEdit };
