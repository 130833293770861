import { required, minLength, maxLength, regex, email, minValue, maxValue } from 'react-admin';

const MSGS = {
  tooShort: 'Too short',
  tooLong: 'Too long',
  notNegative: `Value can't be lower than 0`,
  maxValueIs: max => `Value can't be higher than ${max}`,
};

const validations = {
  required: [required()],
  tinyName: [required(), minLength(1, MSGS.tooShort), maxLength(32, MSGS.tooShort)],
  name: [required(), minLength(2, MSGS.tooShort), maxLength(255, MSGS.tooLong)],
  text: [minLength(2, MSGS.tooShort), maxLength(4096, MSGS.tooLong)],
  vin: [regex(/^([a-hj-npr-z0-9]){17}$/, 'Must be a valid VIN code')],
  get email() {
    return [...validations.name, email()];
  },
  get zipCode() {
    return [...validations.name, regex(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid Zip code')];
  },
  baseCostFee: [required(), minValue(0, MSGS.notNegative)],
  get driverFee() {
    return [...validations.baseCostFee, maxValue(1, MSGS.maxValueIs(1))];
  },
  get feeExpression() {
    return [
      ...validations.name,
      regex(
        /^(?: |\b(?:time|minute_cost|weight|lbs_cost|distance|mile_cost)\b|\d+(?:\.\d*)?|[*/+\-()])+$/,
        'Invalid expression shape',
      ),
    ];
  },
  inventoryWeight: [required(), minValue(0, MSGS.notNegative)],
  distanceWeight: [required(), minValue(0, MSGS.notNegative)],
  get pickupExpression() {
    return [
      ...validations.name,
      regex(
        /^(?: |\b(?:distance_relative|distance|biggest_distance|inventory_relative|inventory_weight|distance_weight)\b|\d+(?:\.\d*)?|[*/+\-()])+$/,
        'Invalid expression shape',
      ),
    ];
  },
};

export { validations };
