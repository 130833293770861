import { HttpError } from 'react-admin';
export const DELIVERY_FEE = 'DELIVERY_FEE';
export const PICKUP_POINTS = 'PICKUP_POINTS';

const getURL = expression => {
  if (expression === DELIVERY_FEE) {
    return `${baseUrl}/shopify/delivery/fee_setup`;
  } else if (expression === PICKUP_POINTS) {
    return `${baseUrl}/shopify/pickup_points/setup`;
  } else {
    throw new Error(
      `Unknown expression. Available expressions are: ${DELIVERY_FEE} and ${PICKUP_POINTS}.`,
    );
  }
};

const baseUrl = process.env.REACT_APP_BASE_URL;

const fetchData = async expression => {
  const token = localStorage.getItem('access_token');
  if (!token) {
    return Promise.reject(new Error('No access token found.'));
  }

  try {
    const request = new Request(getURL(expression), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      }),
    });
    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(new Error(response.statusText));
    }

    return await response.json();
  } catch (e) {
    return Promise.reject(e);
  }
};

const putData = async (expression, record) => {
  const token = localStorage.getItem('access_token');
  if (!token) {
    return Promise.reject(new Error('No access token found.'));
  }

  try {
    const request = new Request(getURL(expression), {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(record),
    });
    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(new Error(response.statusText));
    }

    return await response.json();
  } catch (e) {
    return Promise.reject(e);
  }
};

function getErrorMessage(json) {
  if (typeof json === 'string') {
    return json;
  }

  if (json && json.errors) {
    const message = json.errors.map(({ field, reason }) => `${field}: ${reason}`).join('</br>');
    return message;
  }

  return 'unknow error';
}

const getOrderData = (orderUrl, options) => {
  const token = localStorage.getItem('access_token');
  options = options || {};

  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json',
    });
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json; charset=utf-8');
  }

  requestHeaders.set('Authorization', `Bearer ${token}`);

  const url = `${baseUrl}${orderUrl}`;
  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        const message = getErrorMessage(json);
        return Promise.reject(new HttpError(message, status, json));
      }
      return Promise.resolve({ json });
    });
};

const changeSupplier = async (orderId, payload) => {
  const token = localStorage.getItem('access_token');
  if (!token) {
    return Promise.reject(new Error('No access token found.'));
  }

  try {
    const request = new Request(`${baseUrl}/orders/${orderId}/locations`, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(payload),
    });
    const response = await fetch(request);
    if (response.status === 200) {
      return 'success';
    }

    if (response.status < 200 || response.status >= 300) {
      // return Promise.reject(new Error(response.statusText));
      return 'error';
    }

    return await response.json();
  } catch (e) {
    return Promise.reject(e);
  }
};

export { fetchData, putData, changeSupplier, getOrderData };
