import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const SaveOnCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      {...props}
      label="Save and edit"
      redirect={false}
      submitOnEnter={false}
      style={{ marginRight: '30px' }}
    />
    <SaveButton {...props} label="Save and show" redirect="show" submitOnEnter variant="outlined" />
  </Toolbar>
);

export { SaveOnCreateToolbar };
