import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  ReferenceField,
  Filter,
  SelectInput,
} from 'react-admin';

import { ListActionButtons, ListPagination } from '../common';
import { OrderTypeField } from './CustomComponents'

const OrderFilter = props => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      label="Status"
      source="status"
      choices={[
        { id: 'received', name: 'Received' },
        { id: 'prepared', name: 'Prepared' },
        { id: 'accepted', name: 'Accepted' },
        { id: 'started', name: 'Started' },
        { id: 'picked', name: 'Picked-up' },
        { id: 'delivered', name: 'Delivered' },
        { id: 'cancelled', name: 'Cancelled' },
      ]}
    />
  </Filter>
);

const OrderList = props => (
  <List
    title="RUNR - Orders"
    filters={<OrderFilter />}
    bulkActionButtons={<ListActionButtons />}
    pagination={<ListPagination />}
    perPage={25}
    sort={{ field: 'order_data.created_at', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="status" />
      <TextField source="shopify_order_no" label="Shopify Order #" />
      <TextField source="order_data.total_price" label="Total price" />
      <DateField source="order_data.created_at" label="Order time" showTime />
      <TextField source="delivery_eta" label="Delivery ETA" />
      <DateField source="delivered_at" label="Delivery Time" showTime />
      <OrderTypeField label="Order Type" />
      <ReferenceField label="Driver" source="user_id" reference="drivers">
        <TextField source="first_name" />
      </ReferenceField>

      <EditButton />
    </Datagrid>
  </List>
);

export { OrderList };
