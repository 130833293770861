import React from 'react';
import { map, uniqBy, values } from 'lodash/fp';
import { Filter, SelectInput, TextInput, ReferenceInput } from 'react-admin';

const filtersKeys = {
  suppliers: ['street', 'city', 'state', 'postal_code', 'phone', 'email', 'website'],
  supplier_stores: ['store_number', 'street', 'city', 'state', 'postal_code', 'phone', 'email'],
};

const getFilterChoices = (key, data) =>
  uniqBy('id', map(i => ({ id: i[key], name: i[key] }))(values(data)));

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
    {props.resource === 'supplier_stores' && (
      <ReferenceInput label="Supplier" source="supplier_id" reference="suppliers" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}
    {map(key => <SelectInput key={key} source={key} choices={getFilterChoices(key, props.data)} />)(
      filtersKeys[props.resource],
    )}
  </Filter>
);

export { ListFilters };
