import React from 'react';
import { omit } from 'lodash/fp';
import { BulkDeleteButton, withDataProvider } from 'react-admin';

import { crudDeleteMany } from '../../utils/dataProvider/actions';

const ListActionButtons = withDataProvider(props => (
  <BulkDeleteButton
    {...omit('dataProvider', props)}
    undoable={false}
    crudDeleteMany={() => crudDeleteMany(props.selectedIds, props.basePath)}
  />
));

export { ListActionButtons };
