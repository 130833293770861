import React from 'react';
import { ShowButton } from 'react-admin';

import { BaseActions } from './BaseActions';

const EditActions = ({ basePath, data, children }) => (
  <BaseActions>
    {children}
    <ShowButton basePath={basePath} record={data} />
  </BaseActions>
);

export { EditActions };
