import React from 'react';
import { TextField, Labeled } from 'react-admin';

const style = { display: 'block', maxWidth: '100px', maxHeight: '100px' };

const DocumentField = ({ record, source, label, ...props }) => (
  <Labeled label={label}>
    <>
      <TextField source={source} record={record} {...props} />
      {!!record[source] && (
        <a href={record[source]} target="_blank" rel="noopener noreferrer">
          <img alt={record[source]} style={style} src={record[source]} />
        </a>
      )}
    </>
  </Labeled>
);

export { DocumentField };
