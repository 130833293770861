import React from 'react';
import { List, Datagrid, TextField, ReferenceField, EditButton } from 'react-admin';

import { ListActionButtons, ListFilters, ListPagination } from '../common';

const VehicleList = props => (
  <List
    title="RUNR - Vehicles"
    filters={<ListFilters resource={props.resource} />}
    bulkActionButtons={<ListActionButtons />}
    pagination={<ListPagination />}
    perPage={25}
    sort={{ field: 'name', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <ReferenceField
        label="Driver"
        source="user_id"
        reference="drivers"
        basePath="/drivers"
        link="show"
      >
        <TextField source="first_name" />
      </ReferenceField>
      <TextField source="brand" label="Make" />
      <TextField source="model" />
      <TextField source="year" />
      <TextField source="color" />
      <EditButton />
    </Datagrid>
  </List>
);

export { VehicleList };
