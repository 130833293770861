import React, { useState } from 'react';
import { useStyles } from '../common/styles';
import InviteIcon from '@material-ui/icons/Mail';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, useDataProvider, showNotification as showNotificationAction } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

const InviteButton = ({ record, showNotification }) => {
  const dataProvider = useDataProvider();

  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    dataProvider
      .checkrInvite(record)
      .then(({ data }) => {
        setLoading(false);
        showNotification('drivers.notifications.checkr_invitation.success');
      })
      .catch(error => {
        setLoading(false);
        showNotification('drivers.notifications.checkr_invitation.error');
      });
  };
  const classes = useStyles();

  return (
    <>
      <Button
        label="Send Checkr invitation"
        onClick={e => {
          e.stopPropagation();
          handleClick();
        }}
      >
        <InviteIcon />
      </Button>
      {!!loading && (
        <div className={classes.progressWrapper}>
          <CircularProgress size={20} />
        </div>
      )}
    </>
  );
};

const enhance = compose(
  connect(
    null,
    { showNotification: showNotificationAction },
  ),
);

export default enhance(InviteButton);
