import React from 'react';
import { List, Datagrid, TextField, EmailField, ReferenceField, EditButton } from 'react-admin';

import { ListActionButtons, ListFilters, ListPagination } from '../common';

const StoreList = props => (
  <List
    title="RUNR - Stores"
    filters={<ListFilters resource={props.resource} />}
    bulkActionButtons={<ListActionButtons />}
    pagination={<ListPagination />}
    perPage={25}
    sort={{ field: 'name', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="slug" label="Store ID" />
      <TextField source="name" />
      <TextField source="store_number" />
      <ReferenceField
        label="Supplier"
        source="supplier_id"
        reference="suppliers"
        basePath="/suppliers"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="street_number" />
      <TextField source="street" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="postal_code" />
      <TextField source="phone" />
      <EmailField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);

export { StoreList };
